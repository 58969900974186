import mock from "../mock"
const data = [
  {
    degree: "UG",
    qualification:"B.SC",
    specialization:["Physics,Chemistry,Computer Science"],
    status: "inactive",
    action: "Edit"
  },
  {
    degree: "PG",
    qualification:"M.SC",
    specialization:["Physics,Chemistry,Computer Science,Physics,Chemistry,Computer Science,Chemistry,Computer Science,Physics,Chemistry,Computer Science,Physics,Chemistry,Computer Science,Physics,Chemistry,Computer Science,Physics,Chemistry,Computer Science,Physics,Chemistry,Computer Science"],
    status: "active",
    action: "Edit"
  },
  {
    degree: "PG",
    qualification:"MA",
    specialization:["Tamil,English,History"],
    status: "inactive",
    action: "Edit"
  },
  {
    degree: "UG",
    qualification:"B.Com",
    specialization:["Commerce"],
    status: "inactive",
    action: "Edit"
  },
  {
    degree: "Diploma",
    qualification:"",
    specialization:["Beautician,Fashion Designing"],
    status: "inactive",
    action: "Edit"
  },
  
  
  

 
 
  
]
mock.onGet("/api/qualification_mapping/data").reply(200, {
  data
})
