import mock from "../mock"
const data = [
  {
    educational_category: "UG",
    specialization:"Computer Science",
    qualification:["B.Sc,M.Sc,M.Phil"],
    action: "Edit"
  },
  {
    educational_category: "UG",
    specialization:"Commerce",
    qualification:["B.Com,M.Com"],
    action: "Edit"
  },
  
]
mock.onGet("/api/specialization_mapping/data").reply(200, {
  data
})
