import mock from "../mock"
const data = [
  {
    facility: "PF",
   
    status: "Inactive",
    languagecount:"0",
    action: "Edit"
  },
  {
    facility: "ESI",
  
    status: "Active",
    languagecount:"3",
    action: "Delete"
  },
  
  
]
mock.onGet("/api/facility/data").reply(200, {
  data
})
