import mock from "../mock"
const data = [
  {
    nameofuser: "John123",
    username: "John",
    designation: "Developer",
    userrole: "Game Developer",
    password: "12345",
    status: "Inactive",
    action: "Edit"
  },
  {
    nameofuser: "Cena123",
    username: "Cena",
    designation: "Teacher",
    userrole: "Maths Teacher",
    password: "12345",
    status: "Active",
    action: "Delete"
  },
  {
    nameofuser: "Arun123",
    username: "Arun",
    designation: "Driver",
    userrole: "Bus Driver",
    password: "12345",
    status: "Inactive",
    action: "Edit"
  },
  
]
mock.onGet("/api/usermaster/data").reply(200, {
  data
})
