
// module.exports = global.commonvariable = {
//   getcommon: {
//     variable: {
//       // GOOGLE_RECAPTCHA_SITE_KEY: '6Ld2baEaAAAAAKp_Lkng_9A4dQyFoIZNmBCOlsxp', //Localhost
//       // GOOGLE_RECAPTCHA_SITE_KEY:'6Le4cWcdAAAAAI2sWo4-W0AVpT53RKDMn302gRLl', //250
//       //GOOGLE_RECAPTCHA_SECRECT_KEY:'6Le4cWcdAAAAACgyh-P9c4K8b0hrZ_kPOyqwlSX9', //250
//       // GOOGLE_RECAPTCHA_SECRECT_KEY: '6LcnVqIaAAAAAJ_tUamGol9suGaPw1bbq9CPL1fn',//Test Secrect Key
//       //  GOOGLE_RECAPTCHA_SITE_KEY: '6Ld9LawdAAAAAL3fDjgtYMFkweB7qFoqcudk6Ixu',//UAT
//       //  GOOGLE_RECAPTCHA_SECRECT_KEY: '6Ld9LawdAAAAAKdSBwo5LZp0FnWnVKxDWBbo-ZY0',//UAT Secrect Key
//       // GOOGLE_RECAPTCHA_SITE_KEY: '6LcWLqEaAAAAAKx031CQxD9bCJf5naBnc2l9OqbC',//Live
//       // GOOGLE_RECAPTCHA_SECRECT_KEY: '6LcWLqEaAAAAALLPeLoeC8E_01Lw6jsH05z0vryG',//Live Secrect Key

//       //  GOOGLE_RECAPTCHA_SECRECT_KEY: '6LfbfsAgAAAAAMFEZQwCSyhPyDJc2M4xQZmsS_OT',//Live jobanya Secrect Key
//       //  GOOGLE_RECAPTCHA_SECRECT_KEY: '6LfbfsAgAAAAAMFEZQwCSyhPyDJc2M4xQZmsS_OT',//Live jobanya Secrect Key


//       //  GOOGLE_RECAPTCHA_SITE_KEY: '6LfbfsAgAAAAADl4MuK42yB8JwMqXg8sQdkYoGiM',//Live jobanya
//       //  GOOGLE_RECAPTCHA_SECRECT_KEY: '6LfbfsAgAAAAAMFEZQwCSyhPyDJc2M4xQZmsS_OT',//Live jobanya Secrect Key
//       //  GOOGLE_RECAPTCHA_SITE_KEY: '6LfbfsAgAAAAADl4MuK42yB8JwMqXg8sQdkYoGiM',//Live jobanya
//       //  GOOGLE_RECAPTCHA_SECRECT_KEY: '6LfbfsAgAAAAAMFEZQwCSyhPyDJc2M4xQZmsS_OT',//Live jobanya Secrect Key







//       // GOOGLE RECPATCHA DETAILS START HERE - BY KAVITHA ON 22-04-2023

//       // GOOGLE_RECAPTCHA_SITE_KEY: '6Ld2baEaAAAAAKp_Lkng_9A4dQyFoIZNmBCOlsxp', //Localhost
//       //  GOOGLE_RECAPTCHA_SITE_KEY: '6LdsYuYnAAAAAKcxlxYFKGblxf-Ps13VNc7A-LWZ',//Test
//       GOOGLE_RECAPTCHA_SITE_KEY: '6LfbfsAgAAAAADl4MuK42yB8JwMqXg8sQdkYoGiM',//Live jobanya
//       apikey_map: 'AIzaSyAcNVIX0ZSTMYUOGG7rgFZhKc0AWQ8xpt4', //Map Key

//       default_lan_code: 2,
//       lbacitve: 'Active',
//       lbinactive: 'Inactive',
//       lbtotalcount: 'Total',
//       lbapproval: 'Approval',
//       // lbrejected: 'Rejected',
//       lbexpired: 'Expired',
//       lbpending: 'Pending',
//       lbsmscount: 'Available Credits',
//       err_atleastchooseone: 'Please enter required fields.',
//       blocked_msg: 'Session Expired',
//       timeset: 3000,
//       no_records: '<p>No records found</p>',
//       err_chooseenglish: 'Please enter English language.',
//       color_app_portal_logo: "#2e2f30",
//       wished: 10,
//       unwished: 13,
//       limitvalue: 25,
//       skipvalue: 0,
//       Employee_all_statuscode: 0,
//       Employee_active_statuscode: 1,
//       Employee_inActive_statuscode: 2,
//       Employee_block_statuscode: 6,
//       Employee_pending_statuscode: -1,
//       Employee_approval_statuscode: 5,
//       Employee_rejected_statuscode: 9,
//       statuscode: 4,
//       version: '1.0.29',
//       urlRegex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
//       textpattern: /^[a-zA-Z ,]*$/,
//       numberonly: /^[0-9]*$/,
//       passwordpattern: /^[a-zA-Z 0-9]*$/,
//       govorgpattern: /^[a-zA-Z ,&""./;:()''@#$!_-]*$/,
//       allpattern: /^[a-zA-Z ,&""./;:()+%^*=><}{''@#$!_-]*$/,
//       allnumspecpattern: /^[a-zA-Z0-9 ,&""./;:()+%^*=><}{''@#$!_-]*$/,
//       alphabetpattern: /^[a-zA-Z ,]*$/,
//       institutionpattern: /^[a-zA-Z &' ]*$/,
//       numberpattern: /^[0-9]*$/,
//       Document_type: /^[A-Z0-9]*$/,
//       employer_gstRegax: /^[[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}]*$/,
//       //IMPORT OPTIONS LINK FROM AWS-START//

//       import_state:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/State_import_sample.xlsx',
//       import_district:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/District_import_sample.xlsx',
//       import_city:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/City_import_sample.xlsx',
//       import_taluk:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Taluk_import_sample.xlsx',
//       import_jobfunction:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/JobFunction_import_sample.xlsx',
//       import_skill:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Skills_import_sample.xlsx',
//       import_desigination:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Desigination_import_sample.xlsx',
//       import_educationalqualification:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Educational_qualification_import_sample.xlsx',
//       import_specialization:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Specialization_import_sample.xlsx',
//       import_industry:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Industry_import_sample.xlsx',
//       import_jobrole:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Jobrole_import_sample.xlsx',
//       import_faculty:
//         'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Faculty_import_sample.xlsx',

//       //IMPORT OPTIONS LINK FROM AWS-END//

//       //ZOHO API'S NEEDED VALUES-START//

//       ZOHO_SCOPE: 'ZohoBooks.fullaccess.all',
//       //   ZOHO_SCOPE:'ZohoCRM.Settings.Modules.all',
//       ZOHO_STATE: 'testing',
//       ZOHO_ACCESS_TYPE: 'online',
//       ZOHO_GRANT_TYPE: 'authorization_code',
//       ZOHO_RESPONSE_TYPE: 'code',

//       // // For Local Host
//       // ZOHO_CLIENT_ID: '1000.QF6T0A45H0SSOH0RRWCJPGOH8Y9IWO',
//       // ZOHO_CLIENT_SECRECT: '759fda2b1160f0b80771fbe36dd3f4f1f03ea0e1d1',
//       // ZOHO_REDIRECT_URI: 'http://localhost:3000/zoho_grant_token',

//       //For Test
//       ZOHO_CLIENT_ID: '1000.QR1WQ3LYVT16GUNXVA1273J0LPJ4ZT',
//       ZOHO_CLIENT_SECRECT: 'b0659f8be94199e1f1cf60193ccccc11950585e584',
//       ZOHO_REDIRECT_URI: 'https://admintest.jobanya.com/zoho_grant_token',
//       //https://admintest.jobanya.com/zoho_grant_token
//       // For UAT
//       // ZOHO_CLIENT_ID: '1000.F001SDKTRM1USV7K2740Q5M0Y97OUM',
//       // ZOHO_CLIENT_SECRECT: 'ff414a059f8ad722e5ff5d4da3173dec04957785b8',
//       // ZOHO_REDIRECT_URI: 'https://adminuat.thebestjobs.in/zoho_grant_token',

//       //For JOBANYA LIVE
//       ZOHO_CLIENT_ID: '1000.QR1WQ3LYVT16GUNXVA1273J0LPJ4ZT',
//       ZOHO_CLIENT_SECRECT: 'b0659f8be94199e1f1cf60193ccccc11950585e584',
//       // ZOHO_CLIENT_ID: '1000.2M6W6F0JBFWW8D0MG0KVLM2X2AV1VL',
//       // ZOHO_CLIENT_SECRECT: '515ce91bb7e56ec126e76dfb3605e782027eec5628',
//       // ZOHO_REDIRECT_URI: 'https://adminportal.jobanya.com/zoho_grant_token'

//       //ZOHO API'S NEEDED VALUES-END//
//     },
//     panconfig: {
//       dirName: 'employer-pan-test', //Test
//       // dirName: "employer-pan/",  //Live
//       bucketName: 'bj-employer-upload',
//       region: 'ap-south-1',
//       accessKeyId: 'AKIA3DO4IRQFX2M642PX',
//       secretAccessKey: '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',
//       successActionStatus: 201
//     },

//     aadharconfig: {
//       dirName: 'employer-aadhar-test', //Test
//       // dirName: "employer-aadhar/",  //Live
//       bucketName: 'bj-employer-upload',
//       region: 'ap-south-1',
//       accessKeyId: 'AKIA3DO4IRQFX2M642PX',
//       secretAccessKey: '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',
//       successActionStatus: 201
//     },
//     gstinconfig: {
//       dirName: 'employer-gstn-test', //Test
//       // dirName: 'employer-gstn/',  //Live
//       bucketName: 'bj-employer-upload',
//       region: 'ap-south-1',
//       accessKeyId: 'AKIA3DO4IRQFX2M642PX',
//       secretAccessKey: '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',
//       successActionStatus: 201
//     },
//     documentconfig: {
//       dirName: 'employer-document-test', //Test
//       // dirName: "employer-document/",  //Live
//       bucketName: 'bj-employer-upload',
//       region: 'ap-south-1',
//       accessKeyId: 'AKIA3DO4IRQFX2M642PX',
//       secretAccessKey: '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',
//       successActionStatus: 201
//     },
//     awsconfig: {
//       // employee_bucketName: "bestjobs-employee-upload",
//       // admin_bucketName: "bestjobs-admin-upload",
//       employee_bucketName: 'bj-employee-upload',
//       admin_bucketName: 'bj-admin-upload',

//       // *Live Bucket-Folder Start*
//       // gnjoblogo_dirName: "gnjob-uploads",
//       // industrylogo_dirName: "industry-logo",
//       // gnorganizationlogo_dirName: "gnorgainsation-logo",
//       // jobfunctionlogo_dirName: "jobfunction-logo",
//       // districtlogo_dirName: 'district-logo',
//       // splashimages_dirName: "splash-images",
//       // welcomescreen_dirName: "welcomescreen-images",
//       // newsimage_dirName: "news-uploads",
//       // profilepic_dirName: "profile-picture",
//       // resume_dirName: "employee-resume",
//       // pushnotificationimg_dirName: 'push-notification',
//       // *Live Bucket-Folder End*

//       // *Test Bucket-Folder Start*
//       gnjoblogo_dirName: 'gnjob-uploads-test',
//       industrylogo_dirName: 'industry-logo-test',
//       gnorganizationlogo_dirName: 'gnorgainsation-logo-test',
//       jobfunctionlogo_dirName: 'jobfunction-logo-test',
//       districtlogo_dirName: 'district-logo-test',
//       splashimages_dirName: 'splash-images-test',
//       welcomescreen_dirName: 'welcomescreen-images-test',
//       newsimage_dirName: 'news-uploads-test',
//       profilepic_dirName: 'profile-picture-test',
//       resume_dirName: 'employee-resume-test',
//       pushnotificationimg_dirName: 'push-notification-test',
//       // *Test Bucket-Folder end*

//       // region: "us-east-2",
//       region: 'ap-south-1',
//       accessKeyId: 'AKIA3DO4IRQFX2M642PX',
//       secretAccessKey: 'umMnLUZdfrTBPvolfpzBHzl1bvLRA3pu7UXUo37w',
//       successActionStatus: 201
//     }
//     // rest of your translation object
//   }
//   // other global config variables you wish
// }


// Dev
module.exports = global.commonvariable = {
  getcommon: {
    variable: {
      
      apikey_map: 'AIzaSyAcNVIX0ZSTMYUOGG7rgFZhKc0AWQ8xpt4', //Map Key

      default_lan_code: 2,
      lbacitve: 'Active',
      lbinactive: 'Inactive',
      lbtotalcount: 'Total',
      lbapproval: 'Approval',
      // lbrejected: 'Rejected',
      lbexpired: 'Expired',
      lbpending: 'Pending',
      lbsmscount: 'Available Credits',
      err_atleastchooseone: 'Please enter required fields.',
      blocked_msg: 'Session Expired',
      timeset: 3000,
      no_records: '<p>No records found</p>',
      err_chooseenglish: 'Please enter English language.',
      color_app_portal_logo: "#2e2f30",
      wished: 10,
      unwished: 13,
      limitvalue: 25,
      skipvalue: 0,
      Employee_all_statuscode: 0,
      Employee_active_statuscode: 1,
      Employee_inActive_statuscode: 2,
      Employee_block_statuscode: 6,
      Employee_pending_statuscode: -1,
      Employee_approval_statuscode: 5,
      Employee_rejected_statuscode: 9,
      statuscode: 4,
      version: '1.0.29',
      urlRegex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      textpattern: /^[a-zA-Z ,]*$/,
      numberonly: /^[0-9]*$/,
      passwordpattern: /^[a-zA-Z 0-9]*$/,
      govorgpattern: /^[a-zA-Z ,&""./;:()''@#$!_-]*$/,
      allpattern: /^[a-zA-Z ,&""./;:()+%^*=><}{''@#$!_-]*$/,
      allnumspecpattern: /^[a-zA-Z0-9 ,&""./;:()+%^*=><}{''@#$!_-]*$/,
      alphabetpattern: /^[a-zA-Z ,]*$/,
      institutionpattern: /^[a-zA-Z &' ]*$/,
      numberpattern: /^[0-9]*$/,
      Document_type: /^[A-Z0-9]*$/,
      employer_gstRegax: /^[[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}]*$/,
      //IMPORT OPTIONS LINK FROM AWS-START//

      import_state:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/State_import_sample.xlsx',
      import_district:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/District_import_sample.xlsx',
      import_city:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/City_import_sample.xlsx',
      import_taluk:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Taluk_import_sample.xlsx',
      import_jobfunction:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/JobFunction_import_sample.xlsx',
      import_skill:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Skills_import_sample.xlsx',
      import_desigination:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Desigination_import_sample.xlsx',
      import_educationalqualification:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Educational_qualification_import_sample.xlsx',
      import_specialization:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Specialization_import_sample.xlsx',
      import_industry:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Industry_import_sample.xlsx',
      import_jobrole:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Jobrole_import_sample.xlsx',
      import_faculty:
        'https://bj-excel-sample.s3.ap-south-1.amazonaws.com/Faculty_import_sample.xlsx',

      //IMPORT OPTIONS LINK FROM AWS-END//

      //ZOHO API'S NEEDED VALUES-START//

      ZOHO_SCOPE: 'ZohoBooks.fullaccess.all',
      ZOHO_STATE: 'testing',
      ZOHO_ACCESS_TYPE: 'online',
      ZOHO_GRANT_TYPE: 'authorization_code',
      ZOHO_RESPONSE_TYPE: 'code',

      // For Local Host
      // ZOHO_CLIENT_ID: '1000.WIC38MDF9ILCXZXW8NG4ASLJPVBSMR',
      // ZOHO_CLIENT_SECRECT: '06b6fe1edd12a731aec2e8a8a31f32ffbe8804a999',
      // ZOHO_REDIRECT_URI: 'http://localhost:3000/zoho_grant_token',

      //For Test
      // ZOHO_CLIENT_ID: '1000.QR1WQ3LYVT16GUNXVA1273J0LPJ4ZT',
      // ZOHO_CLIENT_SECRECT: 'b0659f8be94199e1f1cf60193ccccc11950585e584',
      // ZOHO_REDIRECT_URI: 'https://testadmin.thebestjobs.in/zoho_grant_token',

      // For UAT
      // ZOHO_CLIENT_ID: '1000.F001SDKTRM1USV7K2740Q5M0Y97OUM',
      // ZOHO_CLIENT_SECRECT: 'ff414a059f8ad722e5ff5d4da3173dec04957785b8',
      // ZOHO_REDIRECT_URI: 'https://adminuat.thebestjobs.in/zoho_grant_token',

      //For JOBANYA LIVE
      ZOHO_CLIENT_ID: '1000.2M6W6F0JBFWW8D0MG0KVLM2X2AV1VL',
      ZOHO_CLIENT_SECRECT: '515ce91bb7e56ec126e76dfb3605e782027eec5628',
      ZOHO_REDIRECT_URI: 'http://adminportal.jobanya.com/zoho_grant_token'

      //ZOHO API'S NEEDED VALUES-END//
    },
    panconfig: {
      // dirName: 'employer-pan-test', //Test
      dirName: "employer-pan/",  //Live
      bucketName: 'bj-employer-upload',
      region: 'ap-south-1',
      accessKeyId: 'AKIA3DO4IRQFX2M642PX',
      secretAccessKey: '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',
      successActionStatus: 201
    },

    aadharconfig: {
      // dirName: 'employer-aadhar-test', //Test
      dirName: "employer-aadhar/",  //Live
      bucketName: 'bj-employer-upload',
      region: 'ap-south-1',
      accessKeyId: 'AKIA3DO4IRQFX2M642PX',
      secretAccessKey: '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',
      successActionStatus: 201
    },
    gstinconfig: {
      // dirName: 'employer-gstn-test', //Test
      dirName: 'employer-gstn/',  //Live
      bucketName: 'bj-employer-upload',
      region: 'ap-south-1',
      accessKeyId: 'AKIA3DO4IRQFX2M642PX',
      secretAccessKey: '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',
      successActionStatus: 201
    },
    documentconfig: {
      // dirName: 'employer-document-test', //Test
      dirName: "employer-document/",  //Live
      bucketName: 'bj-employer-upload',
      region: 'ap-south-1',
      accessKeyId: 'AKIA3DO4IRQFX2M642PX',
      secretAccessKey: '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',
      successActionStatus: 201
    },
    awsconfig: {
      // employee_bucketName: "bestjobs-employee-upload",
      // admin_bucketName: "bestjobs-admin-upload",
      employee_bucketName: 'bj-employee-upload',
      admin_bucketName: 'bj-admin-upload',

      // *Live Bucket-Folder Start*
      gnjoblogo_dirName: "gnjob-uploads",
      industrylogo_dirName: "industry-logo",
      gnorganizationlogo_dirName: "gnorgainsation-logo",
      jobfunctionlogo_dirName: "jobfunction-logo",
      districtlogo_dirName: 'district-logo',
      splashimages_dirName: "splash-images",
      welcomescreen_dirName: "welcomescreen-images",
      newsimage_dirName: "news-uploads",
      profilepic_dirName: "profile-picture",
      resume_dirName: "employee-resume",
      pushnotificationimg_dirName: 'push-notification',
      // *Live Bucket-Folder End*

      // *Test Bucket-Folder Start*
      // gnjoblogo_dirName: 'gnjob-uploads-test',
      // industrylogo_dirName: 'industry-logo-test',
      // gnorganizationlogo_dirName: 'gnorgainsation-logo-test',
      // jobfunctionlogo_dirName: 'jobfunction-logo-test',
      // districtlogo_dirName: 'district-logo-test',
      // splashimages_dirName: 'splash-images-test',
      // welcomescreen_dirName: 'welcomescreen-images-test',
      // newsimage_dirName: 'news-uploads-test',
      // profilepic_dirName: 'profile-picture-test',
      // resume_dirName: 'employee-resume-test',
      // pushnotificationimg_dirName: 'push-notification-test',
      // *Test Bucket-Folder end*

      // region: "us-east-2",
      region: 'ap-south-1',
      accessKeyId: 'AKIA3DO4IRQFX2M642PX',
      secretAccessKey: 'umMnLUZdfrTBPvolfpzBHzl1bvLRA3pu7UXUo37w',
      successActionStatus: 201
    }
    // rest of your translation object
  }
  // other global config variables you wish
}
