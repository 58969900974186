import mock from "../mock"
const data = [
  {
    jobpostid:"JB001",  
    jobfunction: "Driver",
    jobrole: "Car Driver",
    response1: "10",
    response2: "15",
    response3: "8",
    response4: "5",  
    posteddate: "02/03/2020", 
    lastdate: "12/07/2020",  
    status: "Pending"
  },
  {
    jobpostid:"JB002",  
    jobfunction: "Developer",
    jobrole: "Android Developer",
    response1: "10",
    response2: "25",
    response3: "18",
    response4: "5",  
    posteddate: "02/03/2020", 
    lastdate: "10/05/2020",  
    status: "Approved"
  },
  {
    jobpostid:"JB003",  
    jobfunction: "Manager",
    jobrole: "Accounts Manager",
    response1: "10",
    response2: "5",
    response3: "18",
    response4: "5",  
    posteddate: "02/03/2020",  
    lastdate: "08/03/2020",  
    status: "Approved"
  },
  {
    jobpostid:"JB004",  
    jobfunction: "Teacher",
    jobrole: "Sports Teacher",
    response1: "10",
    response2: "19",
    response3: "10",
    response4: "5",  
    posteddate: "01/03/2020",  
    lastdate: "02/03/2020",  
    status: "Pending"
  },
  {
    jobpostid:"JB005",  
    jobfunction: "Driver",
    jobrole: "Lorry Driver",
    response1: "12",
    response2: "8",
    response3: "12",
    response4: "15",    
    posteddate: "02/03/2020", 
    lastdate: "02/12/2020",  
    status: "Pending"
  }
  
]
mock.onGet("/api/jobpostlist_data/data").reply(200, {
  data
})