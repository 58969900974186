import React from "react"
import axios from "axios";   

const common_navbar = [
  { 
    id:'dashboard',   type: "item", 
    icon: <i className='fa fa-tachometer  icon_style'></i>, 
    navlink :'/Dashboard', 
    permissions: ["admin", "editor"],
  },
  { 
    id: "employermanagement", 
    type: "collapse", 
    icon: <i className='fa fa-users icon_style'></i>, 
 },
  {
    id: "employee",  type: "item", 
    icon: <i className='fa fa-id-badge icon_style'></i>,
    permissions: ["admin", "editor"], 
    navLink: "/Employee_List"
  },
  {
    id: "jobmanagement",  type: "collapse",
    icon: <i className='fa fa-briefcase icon_style'></i>, 
   
  },
  {
    id: "masters", type: "collapse", 
    icon: <i className='fa fa-cogs  icon_style'></i>,
   
  },
];

class navigationConfig extends React.Component {

  state = {    
      ipurl: global.config.geturl.ipaddress.url,
  }

componentDidMount() {
  this.load_bind();
}

load_bind(){
  axios({
    method:'Post',
    url:this.state.ipurl+'userrole/load',     
    crossdomain:true,
    params: {
      usercode:localStorage.getItem('usercode'),
      ipaddress:localStorage.getItem('ipaddress')
      }
    }) 
      .then(response => {
        console.log(response,'menulist')
      });
    
}

}
export default navigationConfig
