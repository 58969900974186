import mock from "../mock"
const data = [
  {
    content: "Logo of Shiva softwares",
   
    status: "Inactive",
    languagecount:"0",
    action: "Edit",
    logo: require("../../assets/img/portrait/small/tamilnadu.png"),
  },
  {
    content: "building of shiva softwares",
    status: "Active",
    languagecount:"3",
    action: "Delete",
    logo: require("../../assets/img/portrait/small/airport.jpg"),
  },
  
  
]
mock.onGet("/api/splashscreen/data").reply(200, {
  data
})
