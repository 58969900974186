import mock from "../mock"
const data = [
  {
    city: "Virudhunagar",
    state:"Tamilnadu",
    district:"Virudhunagar",
    status: "Inactive",
    languagecount:"0",
   
  },
  {
    city: "Madurai",
    state:"Tamilnadu",
    district:"Madurai",
    status: "Active",
    languagecount:"3",
    
  },
  {
    city: "RajaPayalayam",
    state:"Tamilnadu",
    district:"Virudhunagar",
    status: "Inactive",
    languagecount:"0",
   
  },
  
]
mock.onGet("/api/city/data").reply(200, {
  data
})
