import mock from "../mock"
const data = [
  {
    employee_name:"Raju",  
    qualification: "B.E", 
    experience: "10 months",  
    applieddate: "12/07/2020",  
    status: "Pending",
    logo: require("../../assets/img/portrait/small/avatar-s-9.jpg"),
  },
  {
    employee_name:"Ram",  
    qualification: "B.SC", 
    experience: "2 years",  
    applieddate: "10/05/2020",  
    status: "Accepted",
    logo: require("../../assets/img/portrait/small/avatar-s-1.jpg"),
  },
  {
    employee_name:"Chitra",  
    qualification: "B.Tech", 
    experience: "5 years",  
    applieddate: "08/03/2020",  
    status: "Accepted",
    logo: require("../../assets/img/portrait/small/avatar-s-2.jpg"),
  },
  {
    employee_name:"Abirami",  
    qualification: "Ph.D", 
    experience: "10 years",  
    applieddate: "02/03/2020",  
    status: "Pending",
    logo: require("../../assets/img/portrait/small/avatar-s-4.jpg"),
  },
  {
    employee_name:"Lakshmi",  
    qualification: "B.Tech", 
    experience: "8 months",  
    applieddate: "02/12/2020",  
    status: "Pending",
    logo: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
  }
  
]
mock.onGet("/api/appliedlist_data/data").reply(200, {
  data
})