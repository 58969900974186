import mock from "../mock"
const data = [
  {
    job_id: "Jb001",
    posteddate: "01/02/2020",
    employer_name:"SSS",  
    job_function: "Developer",
    job_role: "Android Developer",
    city: "Virudhunagar",
    district: "Virudhunagar",  
    status: "Active",
    logo: require("../../assets/img/portrait/small/company2.jpeg"),
  },
  {
    employer_name:"Google",  
    job_id: "Jb005",
    posteddate: "04/02/2020",
    job_function: "Developer",
    job_role: "Game Developer",
    city: "Sattur",
    district: "Virudhunagar",  
    status: "Expired",
    logo: require("../../assets/img/portrait/small/company1.jpg"),
  },
  {
    employer_name:"KIA",  
    job_id: "Jb105",
    posteddate: "09/02/2020",
    job_function: "Developer",
    job_role: "Game Developer",
    city: "Virudhunagar",
    district: "Virudhunagar",  
    status: "Expired",
    logo: require("../../assets/img/portrait/small/company1.jpg"),
  },
  {
    employer_name:"Diamond Ltd",  
    job_id: "Jb015",
    posteddate: "04/04/2020",
    job_function: "Developer",
    job_role: "Game Developer",
    city: "Arupukottai",
    district: "Virudhunagar",  
    status: "Active",
    logo: require("../../assets/img/portrait/small/company3.jpg"),
  },
  {
    employer_name:"A-Z",  
    job_id: "Jb001",
    posteddate: "14/02/2020",
    job_function: "Professor",
    job_role: "Physics Professor",
    city: "Sivakasi",
    district: "Virudhunagar",  
    status: "Active",
    logo: require("../../assets/img/portrait/small/company4.jpg"),
  }
  
]
mock.onGet("/api/employee_applied_data/data").reply(200, {
  data
})