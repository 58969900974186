import mock from "../mock"
const data = [
  {
    employee_name:"Raju",  
    address: "1/45 TK Nagar",
    qualification: "B.E", 
    experience: "10 months",
    mode: "Applied on 01/07/2020",  
    shortlisteddate: "12/07/2020",  
    joblocation: "Madurai,Tamilnadu",
    jobfunctionrole: "Developer-PHP",
    logo: require("../../assets/img/portrait/small/avatar-s-9.jpg"),
  },
  {
    employee_name:"Ram",
    address: "1/45 TK Nagar",  
    qualification: "B.SC", 
    experience: "2 years",  
    mode: "Invited on 05/05/2020",
    shortlisteddate: "10/05/2020", 
    joblocation: "Madurai,Tamilnadu",
    jobfunctionrole: "Developer-PHP",
    logo: require("../../assets/img/portrait/small/avatar-s-1.jpg"),
  },
  {
    employee_name:"Chitra",  
    address: "1/45 TK Nagar",
    qualification: "B.Tech", 
    experience: "5 years",  
    mode: "Applied on 01/03/2020",
    shortlisteddate: "08/03/2020", 
    joblocation: "Madurai,Tamilnadu",
    jobfunctionrole: "Developer-PHP",
    logo: require("../../assets/img/portrait/small/avatar-s-2.jpg"),
  },
  {
    employee_name:"Abirami",
    address: "1/45 TK Nagar",  
    qualification: "Ph.D", 
    experience: "10 years",  
    mode: "Applied on 01/03/2020",
    shortlisteddate: "02/03/2020", 
    joblocation: "Madurai,Tamilnadu",
    jobfunctionrole: "Developer-PHP",
    logo: require("../../assets/img/portrait/small/avatar-s-4.jpg"),
  },
  {
    employee_name:"Lakshmi",  
    address: "1/45 TK Nagar",
    qualification: "B.Tech", 
    experience: "8 months",  
    mode: "Invited on 20/11/2020",
    shortlisteddate: "02/12/2020",
    joblocation: "Madurai,Tamilnadu",
    jobfunctionrole: "Developer-PHP",
    logo: require("../../assets/img/portrait/small/avatar-s-6.jpg"),
  }
  
]
mock.onGet("/api/shortlistedlist_data/data").reply(200, {
  data
})