import mock from "../mock"
const data = [
  {
    sno:"1",    
    employername: "Shiva Software Solutions", 
    employerlocation: "Virudhunagar", 
    companytype: "IT Service | Private | Company",  
    industrysector: "IT Services", 
    contactinfo: "9784226655",  
    gmailid: "bestjobs@gmail.com",  
    status: "Pending",  
    lastloginon: "2 days ago",
    subscriptionstatus: "Active"
  },
  {
    sno:"2",    
    employername: "Shiva System", 
    employerlocation: "Virudhunagar", 
    companytype: "Private Ltd.",  
    industrysector: "IT Service | Private | Company", 
    contactinfo: "9084226655",  
    gmailid: "padmakannan@gmail.com",  
    status: "Pending",  
    lastloginon: "5 days ago",
    subscriptionstatus: "Active"
  }
  
]
mock.onGet("/api/employer_management/data").reply(200, {
  data
})
