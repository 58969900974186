import mock from "../mock"
const data = [
  {
    governmentorganization: "Tamilnadu Electricity BOard",
    state: "Tamilnadu",
    government_type:"State",
    counter:3,
    status: "Inactive",
    logo: require("../../assets/img/portrait/small/tamilnadu.png"),
  },
  {
    governmentorganization: "Delhi Metro RaIL Corporation",
    state: "Delhi",
    government_type:"State",
    counter:3,
    status: "Active",
    logo: require("../../assets/img/portrait/small/railway.png"),
  },
  {
    governmentorganization: "Airports authority of India",
    state: "-",
    government_type:"Central",
    counter:3,
    status: "Inactive",
    logo: require("../../assets/img/portrait/small/airport.jpg"),
  },
  
]
mock.onGet("/api/governmentorganization/data").reply(200, {
  data
})
