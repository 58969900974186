import mock from "../mock"
const data = [
  {
    id:"Virudhunagar",
    state: "TamilNadu",
    district: "Virudhunagar",
    no_of_city: "28",
    counter: "3",
    status: "Inactive"
  },
  {
    id:"Madurai",
    state: "TamilNadu",
    district: "Madurai",
    no_of_city: "34",
    counter: "2",
    status: "Active"
  },
  {
    id:"Chennai",
    state: "TamilNadu",
    district: "Chennai",
    no_of_city: "30",
    counter: "3",
    status: "Inactive"
  },
  {
    id:"Coimbatore",
    state: "TamilNadu",
    district: "Coimbatore",
    no_of_city: "12",
    counter: "3",
    status: "Inactive"
  },
  {
    id:"Dindugal",
    state: "TamilNadu",
    district: "Dindugal",
    no_of_city: "25",
    counter: "1",
    status: "Active"
  },
  {
    id:"Dharmapuri",
    state: "TamilNadu",
    district: "Dharmapuri",
    no_of_city: "20",
    counter: "3",
    status: "Active"
  },
  {
    id:"Erode",
    state: "TamilNadu",
    district: "Erode",
    no_of_city: "18",
    counter: "3",
    status: "Inactive"
  },
  {
    id:"Kanyakumari",
    state: "TamilNadu",
    district: "Kanyakumari",
    no_of_city: "9",
    counter: "2",
    status: "Active"
  },
  
]
mock.onGet("/api/district/data").reply(200, {
  data
})
