import mock from "../mock"
const data = [
  {
    id: "Teacher",
    designation: " Teacher",
    status: "Inactive"
  },
  {
    id: "Tailor",
    designation: "Tailor",
    status: "Active"
  },
  {
    id: "Head Mistress",
    designation: "Head Mistress",
    status: "Inactive"
  },
  {
    id: "Head Master",
    designation: "Head Master",
    status: "Inactive"
  },
  {
    id: "Nurse",
    designation: "Nurse",
    status: "Active"
  },
  {
    id: "Watchman",
    designation: "Watchman",
    status: "Inactive"
  },
  {
    id: "Officer",
    designation: "Officer",
    status: "Active"
  },
  {
    id: "Manager",
    designation: "Manager",
    status: "Active"
  },
  {
    id: "IT Developer",
    designation: "IT Developer",
    status: "Inactive"
  },
  {
    id: "Game Designer",
    designation: "Game Designer",
    status: "Inactive"
  },
  {
    id: "Account Manager",
    designation: "Account Manager",
    status: "Active"
  }
]
mock.onGet("/api/designation/data").reply(200, {
  data
})
