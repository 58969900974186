import React, { Component } from "react"
import { NavLink } from "react-router-dom"
//import { Disc, X, Circle } from "react-feather"
import classnames from "classnames"
import logo from "../../../../assets/img/logo/web-logo.png"
class SidebarHeader extends Component {
  state = {
    is_welcome: false,
  }
  componentDidMount() {
    let menushow = JSON.parse(localStorage.getItem('usermenulist'));
    if (menushow && menushow.length > 0) {
      let has_menu = menushow.find(m => m.menucode === this.state.menucode);
      if (!has_menu) {
        let has_menu_dashboard = menushow.find(m => m.menucode === 1);
        if (!has_menu_dashboard)
          this.state.is_welcome = true
        // history.push({ pathname: "/masters/Welcome" })
        else
          this.state.is_welcome = false
        // history.push({ pathname: "/Dashboard" })
      }
    }
  }
  render() {
    //for setting height to navbar
    var navbarStyle = {
      height: "67px"
    };

    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow
    } = this.props
    return (
      <div className="navbar-header bg-mycolor" style={navbarStyle}>
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto" style={{ marginTop: "19px", marginLeft: "23px" }}>
            <NavLink to={this.state.is_welcome ? "/masters/Welcome" : "/Dashboard"} className="navbar-brand">
              <div className="brand-logo" />
              <h2 className="brand-text mb-0">JOBANYA</h2>
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                // <Disc

                <i
                  onClick={() => {
                    toggleSidebarMenu(true)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon  d-none d-xl-block font-medium-4 far fa-dot-circle fa-2x color-mywhite",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                  // <i className='fa fa-circle-o fa-2x color-mywhite'
                  <i
                    onClick={() => {
                      toggleSidebarMenu(false)
                      toggle()
                    }}
                    className={classnames(
                      "toggle-icon  d-none d-xl-block font-medium-4 far fa-circle fa-2x color-mywhite",
                      {
                        "text-primary": activeTheme === "primary",
                        "text-success": activeTheme === "success",
                        "text-danger": activeTheme === "danger",
                        "text-info": activeTheme === "info",
                        "text-warning": activeTheme === "warning",
                        "text-dark": activeTheme === "dark"
                      }
                    )}
                    size={20}
                  />
                )}
              {/* <i className='fa fa-times fa-2x color-mywhite ' */}
              <i
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon  d-block d-xl-none font-medium-4 fa fa-times fa-2x color-mywhite",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}
export default SidebarHeader