import mock from "../mock"
const data = [
  {
    sno:"1",    
    orderid:"1",
    planname: "Silver Plan", 
    amount: "2,000", 
    purchaseon: "10/07/2020",  
    paymentstaus: "Pending", 
    employer:"Radhika",
    subscriptionstatus: "Active",  
    validitydate: "13/07/2020"
  },
  {
    sno:"2",    
    orderid:"2",
    planname: "Gold Plan", 
    amount: "2,000", 
    purchaseon: "10/07/2020",  
    paymentstaus: "Approved", 
    employer:"Niveka",
    subscriptionstatus: "Active",  
    validitydate: "13/07/2020"
  }
  
]
mock.onGet("/api/subscription/data").reply(200, {
  data
})
