import mock from "../mock"
const data = [
  {
    newstype: "Labour News",
    newstitle: "Leave Announcement",
    category: "Employer",
    startdate: "2020-6-20",
    enddate: "2020-6-20",
    counter:3,
    status: "Active",
    
  },
  {
    newstype: "Employer News",
    newstitle: "Meeting Announcement",
    category: "Employee",
   startdate: "2020-6-20",
    enddate: "2020-6-20",
    counter:2,
    status: "Inactive",
   
  },
  
  
]
mock.onGet("/api/news/data").reply(200, {
  data
})
