module.exports = global.config = {
    geturl: {
        ipaddress: {

            // url: "https://adminapi.jobanya.com/api/controller/"// LIVE
            // url: "https://adminapi.jobanya.com/api/controller/"// LIVE

            //  url: "https://api.jobanya.com/api/controller/"// DEV
            //  url: "https://apitest.jobanya.com/api/controller/"// TEST

            // url: "https://devapi.jobanya.com/api/controller/" //dev
            url: "https://uatapi.jobanya.com/api/controller/" //UAT
            // url: "https://apilive.jobanya.com/api/controller/" //Live
            // url: "https://devapi.jobanya.com/api/controller/" //dev


        }
        // rest of your translation object
    }
    // other global config variables you wish
};